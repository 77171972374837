import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { AnalysisView, AnalysisViewSubject } from 'venn-api';
import { checkViewName, saveAnalysisView, savePrivatePortfolio, updatePortfolioV3 } from 'venn-api';
import {
  AnalysisSubject,
  analyticsService,
  assertExhaustive,
  assertNotNil,
  getDefaultStudioViewName,
  getRandomId,
  isOutsideRange,
  isRequestSuccessful,
  logExceptionIntoSentry,
  updateUrlParam,
  useModal,
  VennQueryClient,
} from 'venn-utils';
import type { AfterUnsavedChangeAction, Page, PageInsertOptions, StudioSidePanelContextProps } from 'venn-components';
import { SavedViewMessage, useDebounceToGlobal, UserContext } from 'venn-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { Notifications, NotificationType, getFactorMaxRange, getRangeFromType } from 'venn-ui-kit';
import { cloneDeep, isNil, sortBy } from 'lodash';
import moment from 'moment';
import type { Layout } from 'react-grid-layout';
import { type Snapshot, type UnwrapRecoilValue, useRecoilValue, useRecoilCallback, useSetRecoilState } from 'recoil';
import {
  allocatorAnalysisSubject,
  availableFactorMetrics,
  benchmarkInputs,
  blockAllFactorsSelected,
  blockBenchmarkInput,
  blockDateRangeInputState,
  blockSettingsMap,
  blockSubjectInputGroups,
  currentAnalysisView,
  dateRangeInputsState,
  dateRangeInputDateRangeState,
  hasUnsavedChangesInPrivatesAllocator,
  hasUnsavedPortfolioChangesInAllocator,
  openAllocatorSubject,
  openPrivateAllocatorConfig,
  openPrivateAllocatorPortfolio,
  originalAnalysisSubjectQuery,
  predefinedNotablePeriods,
  selectedBlockIdState,
  studioLeftPanelOpen,
  subjectInputGroups,
  updateInputIds,
  useRecoilValueWithDefault,
  viewPages,
  type CustomViewOptions,
  type BlockId,
  blockAnalysisViewState,
  allBlockIdsState,
  analysisViewNameState,
  blockSettings,
  analysisViewOwnerContextIdState,
  analysisViewTypeState,
  analysisViewIdState,
  isReportState,
  analysisViewSystemTemplateState,
  analysisViewOwnerState,
  hasUnsavedChangesState,
  type DateRangeInputId,
  dateRangeInputGlobalComputedRange,
  primaryFactorLens,
} from 'venn-state';
import type { CustomBlockTypeEnum, CustomizableBlockSetting } from 'venn-utils';

interface InsertBlockOptions {
  insertIndex?: number;
  customBlockType?: CustomBlockTypeEnum;
  globalId?: string;
}

export type StudioToolbarInput = {
  firstOpeningOfTheView: boolean;
  afterUnsavedChangesAction?: AfterUnsavedChangeAction;
  setAfterUnsavedChangesAction: (action?: AfterUnsavedChangeAction) => void;
  setFirstOpeningOfTheView: (firstView: boolean) => void;
  onExport: (
    isInternal: boolean,
    analysisViewName?: string,
    analysisViewId?: string,
    onComplete?: () => void,
  ) => Promise<void>;
  setIsDuplicateReportName: (isDuplicateReportName: boolean) => void;
  setIsCheckingDuplicateReportName: (checking: boolean) => void;
} & Pick<StudioSidePanelContextProps, 'onSelectBlock' | 'onSelectGlobal' | 'onSelectPage'>;

/** Storage as const prevents repeated rerenders. */
const defaultEmptyBlockSettings: UnwrapRecoilValue<typeof blockSettingsMap> = {};
/** Storage as const prevents repeated rerenders. */
const defaultEmptyFactorMetrics: UnwrapRecoilValue<typeof availableFactorMetrics> = [];

/** For tracking purposes, check if full history is selected as any date period OR if any selected dates in any of the ranges are outside of max range */
const isOutsideFactorMaxRange = async (snapshot: Snapshot): Promise<boolean> => {
  const dateRangeIds = await snapshot.getPromise(dateRangeInputsState);
  const factorLens = await snapshot.getPromise(primaryFactorLens);

  return (
    await Promise.allSettled(
      dateRangeIds.map(async (id: DateRangeInputId) => {
        const range = await snapshot.getPromise(dateRangeInputDateRangeState(id));
        const computedMaxRange = await snapshot.getPromise(dateRangeInputGlobalComputedRange(id));
        const maxRange = getFactorMaxRange(computedMaxRange.frequency ?? 'DAILY');

        if (range?.period === 'full_no_factor_constraint') return true;

        const dateRange =
          isNil(range?.from) && isNil(range?.to) && range?.period
            ? getRangeFromType(range.period, maxRange, 'day', 'DAILY', factorLens)
            : range;

        return isOutsideRange(dateRange, maxRange);
      }),
    )
  ).some((result) => result.status === 'fulfilled' && result.value);
};

/** Handle studio's interactions in block/global toolbars, such as save/save as/insert in top bar, and interactions in block's toolbar */
const useStudioToolbar = ({
  firstOpeningOfTheView,
  setFirstOpeningOfTheView,
  afterUnsavedChangesAction,
  setAfterUnsavedChangesAction,
  onSelectBlock,
  onSelectGlobal,
  onExport,
  setIsDuplicateReportName,
  setIsCheckingDuplicateReportName,
  onSelectPage,
}: StudioToolbarInput) => {
  const allNotablePeriods = useRecoilValueWithDefault(predefinedNotablePeriods, undefined);
  const setLeftPanelOpen = useSetRecoilState(studioLeftPanelOpen);
  const setCurrentAnalysisView = useSetRecoilState(currentAnalysisView);
  const preventRedirectOnSave = useRef(false);
  const hasUnsavedReturnsAllocatorChanges = useRecoilValueWithDefault(hasUnsavedPortfolioChangesInAllocator, false);
  const hasUnsavedPrivatesAllocatorChanges = useRecoilValueWithDefault(hasUnsavedChangesInPrivatesAllocator, false);

  const history = useHistory();
  const { profileSettings, currentContext } = useContext(UserContext);
  const blockSettingMapper = useRecoilValueWithDefault(blockSettingsMap, defaultEmptyBlockSettings);

  const factorMetrics = useRecoilValueWithDefault(availableFactorMetrics, defaultEmptyFactorMetrics);

  const [isSaving, setIsSaving] = useState(false);
  const [isOpenReportConfigModal, openReportConfigModal, closeReportConfigModal] = useModal();

  const isReportView = useRecoilValue(isReportState);
  const systemTemplate = useRecoilValue(analysisViewSystemTemplateState);
  const analysisViewName = useRecoilValue(analysisViewNameState);
  const analysisViewId = useRecoilValue(analysisViewIdState);
  const viewOwner = useRecoilValue(analysisViewOwnerState);
  const hasUnsavedChanges = useRecoilValueWithDefault(hasUnsavedChangesState, false);

  const trackSaveView = useCallback(
    async (snapshot: Snapshot, viewId?: string) => {
      analyticsService.viewSaved({
        viewId,
        sourcePage: isReportView ? 'REPORT_LAB' : 'STUDIO',
        outsideFactorMaxRange: await isOutsideFactorMaxRange(snapshot),
      });
    },
    [isReportView],
  );

  const save = useRecoilCallback(
    ({ snapshot }) =>
      async ({
        nameOverride,
        ownerContextIdOverride,
        isSaveAs,
      }: {
        nameOverride?: string;
        ownerContextIdOverride?: string;
        isSaveAs?: boolean;
      }): Promise<string | undefined> => {
        const savingView = Notifications.notify('Saving view...', NotificationType.LOADING);
        try {
          setIsSaving(true);
          const toSaveView = cloneDeep(await snapshot.getPromise(currentAnalysisView));

          toSaveView.customizedViews = toSaveView.customizedViews?.map((view) => {
            if (isSaveAs) {
              // Make sure make a copy for save as item
              return {
                ...view,
                id: undefined,
              };
            }
            return view;
          });
          const finalView = updateInputIds(toSaveView);
          const id = isSaveAs ? undefined : finalView.id;
          const toSaveFinalView = {
            ...finalView,
            name: nameOverride ?? finalView.name,
            ownerContextId: ownerContextIdOverride ?? finalView.ownerContextId,
            id,
          };
          const queryClient = VennQueryClient.getInstance();
          const { content: updatedSavedView } = await queryClient.fetchQuery(
            ['specificAnalysisView', toSaveFinalView],
            () => saveAnalysisView(toSaveFinalView),
          );
          setCurrentAnalysisView(updatedSavedView);
          setIsSaving(false);
          Notifications.notifyUpdate(
            savingView,
            <SavedViewMessage type={updatedSavedView.analysisViewType} />,
            NotificationType.INFO,
          );
          if (preventRedirectOnSave.current) {
            preventRedirectOnSave.current = false;

            return undefined;
          }

          if (id !== updatedSavedView.id) {
            updateUrlParam(history, 'PUSH', 'savedId', updatedSavedView.id);
          }

          trackSaveView(snapshot, updatedSavedView.id);

          return updatedSavedView.id;
        } catch (e) {
          Notifications.notifyUpdate(savingView, 'Failed to save the view', NotificationType.ERROR);
          logExceptionIntoSentry(e);
          setIsSaving(false);
          return undefined;
        }
      },
    [setCurrentAnalysisView, history, trackSaveView],
  );

  const onSave = useRecoilCallback(
    ({ snapshot }) =>
      async (): Promise<{ savedName?: string; savedId?: string }> => {
        const currentOwnerContextId = await snapshot.getPromise(analysisViewOwnerContextIdState);
        const analysisViewType = await snapshot.getPromise(analysisViewTypeState);
        const currentName = await snapshot.getPromise(analysisViewNameState);
        const ownerContextIdOverride = currentOwnerContextId ?? currentContext;
        const timestamp = moment().format('YYYY-MM-DD hh:mm A');
        // If the name field is empty, fallback to save with original name
        const nameOverride = !currentName ? getDefaultStudioViewName(timestamp, analysisViewType) : currentName;
        const savedId = await save({
          nameOverride,
          ownerContextIdOverride,
        });
        analyticsService.ctaClicked({
          destination: undefined,
          text: 'Save',
          purpose: isReportView ? 'Save report' : 'Save studio',
          type: 'button',
          filled: false,
        });
        return { savedId, savedName: nameOverride };
      },
    [currentContext, save, isReportView],
  );

  const onSaveAs = useCallback(
    (name: string, ownerContextId?: string) => {
      save({ nameOverride: name, ownerContextIdOverride: ownerContextId, isSaveAs: true });
      analyticsService.ctaClicked({
        destination: undefined,
        text: 'Save As...',
        purpose: isReportView ? 'Save report' : 'Save studio',
        type: 'button',
        filled: false,
      });
      analyticsService.creatingNewStudios({
        source: 'studio toolbar - save as',
        type: 'template',
        name: systemTemplate,
      });
    },
    [save, isReportView, systemTemplate],
  );

  const onBlockReorderUp = useRecoilCallback(
    ({ set, snapshot }) =>
      (blockId: BlockId) => {
        set(allBlockIdsState, (currentBlockIds) => {
          const reordered = [...currentBlockIds];
          const index = currentBlockIds.findIndex((id) => blockId === id);
          reordered.splice(index - 1, 0, reordered.splice(index, 1)[0]!);
          return reordered;
        });
        snapshot.getPromise(blockSettings(blockId)).then((settings) =>
          analyticsService.ctaClicked({
            purpose: 'move block up',
            locationOnPage: `'${settings.customBlockType} block toolbar'`,
          }),
        );
      },
    [],
  );

  const onBlockReorderDown = useRecoilCallback(
    ({ set, snapshot }) =>
      (blockId: BlockId) => {
        set(allBlockIdsState, (currentBlockIds) => {
          const reordered = [...currentBlockIds];
          const index = currentBlockIds.findIndex((id) => blockId === id);
          reordered.splice(index + 1, 0, reordered.splice(index, 1)[0]!);
          return reordered;
        });
        snapshot.getPromise(blockSettings(blockId)).then((settings) =>
          analyticsService.ctaClicked({
            purpose: 'move block down',
            locationOnPage: `'${settings.customBlockType} block toolbar'`,
          }),
        );
      },
    [],
  );

  const prepareNewBlockObject = useCallback(
    (blockSetting: CustomizableBlockSetting, rowIndex: number): AnalysisView => {
      return {
        refId: getRandomId(),
        analysisViewType: 'ASSEMBLY_CHILD',
        systemTemplate: 'custom',
        subjects: [] as AnalysisViewSubject[],
        customViewOptions: {
          ...(blockSetting.customBlockType === 'NOTABLE_PERIODS'
            ? { selectedNotablePeriods: allNotablePeriods?.map(({ id }) => id) }
            : {}),
          ...(blockSetting.hasFactors ? { allFactorsSelected: true } : {}),
        } satisfies Partial<CustomViewOptions>,
        customizedBlock: {
          settingId: blockSetting.id,
          contributionToPercentage: false,
          // No default metrics for timeseries
          selectedMetrics:
            blockSetting.customBlockType === 'TIMESERIES' || blockSetting.customBlockType === 'PEER_GROUPS'
              ? []
              : blockSetting.defaultMetrics.length
                ? blockSetting.defaultMetrics
                : blockSetting.metrics.map((m) => m.key),
          selectedFactors: blockSetting.hasFactors ? factorMetrics.map((f) => f.id) : [],
          // Use the first item as default
          infoGraphicType: blockSetting.supportedGraphicTypes[0] ?? 'GRID',
        },
        row: rowIndex,
      } as unknown as AnalysisView;
    },
    [allNotablePeriods, factorMetrics],
  );

  const insertBlock = useRecoilCallback(
    ({ set }) =>
      (newBlockId: BlockId, { insertIndex }: InsertBlockOptions = {}, pageInsertOptions?: PageInsertOptions) => {
        set(allBlockIdsState, (current) => {
          const newBlockInsertIndex = insertIndex ?? current.length;
          const newIds = [...current];
          newIds.splice(newBlockInsertIndex, 0, newBlockId);
          return newIds;
        });

        onSelectBlock(newBlockId, {
          scrollIntoView: !isReportView,
          pageIndex: pageInsertOptions?.pageNumber,
        });

        // Ensure we do not insert the block into the grid until the analysis view has been set,
        // Otherwise it's size will be reset as it will not be able to render until this is set
        if (pageInsertOptions !== undefined) {
          const { pageNumber, layout } = pageInsertOptions;
          set(viewPages, (current) => [
            ...current.slice(0, pageNumber),
            {
              ...current[pageNumber]!,
              layout: [
                ...layout.map((l) =>
                  l.i === 'dropping_item'
                    ? {
                        ...l,
                        i: newBlockId,
                      }
                    : l,
                ),
              ],
            },
            ...current.slice(pageNumber + 1),
          ]);
        }
      },
    [onSelectBlock, isReportView],
  );

  const insertBlockView = useRecoilCallback(
    ({ set }) =>
      (view: AnalysisView) => {
        set(blockAnalysisViewState(assertNotNil(view.refId)), view);
      },
    [],
  );

  const linkBlockToDefaults = useRecoilCallback(
    ({ set, snapshot }) =>
      async (blockId?: string) => {
        if (!blockId) {
          return;
        }

        const subjectGroups = await snapshot.getPromise(subjectInputGroups);
        subjectGroups.length && set(blockSubjectInputGroups(blockId), [subjectGroups[0]!]);

        const dateRangeGroups = await snapshot.getPromise(dateRangeInputsState);
        set(blockDateRangeInputState(blockId), dateRangeGroups[0]);

        const benchmarkSettings = await snapshot.getPromise(benchmarkInputs);
        set(blockBenchmarkInput(blockId), benchmarkSettings[0]);
      },
    [],
  );

  const onInsertBlock = useRecoilCallback(
    ({ set }) =>
      async (
        { value: blockSetting }: DropMenuItem<CustomizableBlockSetting>,
        insertIndex?: number,
        pageInsertOptions?: PageInsertOptions,
      ) => {
        const newIndex = insertIndex ?? 0;
        const newBlock = prepareNewBlockObject(blockSetting, newIndex);

        newBlock.refId && set(blockAnalysisViewState(newBlock.refId), newBlock);
        newBlock.refId && set(blockAllFactorsSelected(newBlock.refId), true);
        insertBlockView(newBlock);
        await linkBlockToDefaults(newBlock.refId);
        insertBlock(
          newBlock.refId!,
          {
            insertIndex,
            customBlockType: blockSetting.customBlockType,
          },
          pageInsertOptions,
        );

        return newBlock.refId;
      },
    [insertBlock, linkBlockToDefaults, prepareNewBlockObject, insertBlockView],
  );

  const cloneBlockState = useRecoilCallback(
    ({ set, snapshot }) =>
      async (fromId?: BlockId, toId?: BlockId) => {
        if (!fromId || !toId) {
          return;
        }
        const fromView = cloneDeep(await snapshot.getPromise(blockAnalysisViewState(fromId)));
        set(blockAnalysisViewState(toId), fromView);
      },
    [],
  );

  const onDuplicateBlock = useRecoilCallback(
    ({ snapshot }) =>
      async (blockId: BlockId, insertIndex: number, customBlockType?: CustomBlockTypeEnum) => {
        const newBlockId = getRandomId();
        let pageInsertOptions: PageInsertOptions | undefined;

        const pages = await snapshot.getPromise(viewPages);
        if (isReportView) {
          const pageNumber = pages.findIndex((p: Page) => p.layout.some((l) => l.i === blockId));
          const page: Page = pages[pageNumber]!;
          const sourceLayout = page.layout.find((l) => l.i === blockId);
          const layout = [
            ...page.layout,
            {
              ...sourceLayout!,
              y: sourceLayout!.y + sourceLayout!.h,
              i: newBlockId,
            },
          ];
          pageInsertOptions = {
            pageNumber,
            layout,
          };
        }

        await cloneBlockState(blockId, newBlockId);
        insertBlock(
          newBlockId,
          {
            insertIndex,
            customBlockType,
          },
          pageInsertOptions,
        );
      },
    [cloneBlockState, insertBlock, isReportView],
  );

  const onDeleteBlock = useRecoilCallback(
    ({ set, snapshot }) =>
      async (blockId: string) => {
        const selectedBlockId = await snapshot.getPromise(selectedBlockIdState);
        if (blockId === selectedBlockId) {
          onSelectGlobal();
        }

        if (isReportView) {
          set(viewPages, (current) =>
            current.map((page: Page) => ({
              ...page,
              layout: page.layout.filter((layout) => blockId !== layout.i),
            })),
          );
        }

        set(allBlockIdsState, (allBlockIds) => allBlockIds.filter((id) => id !== blockId));
      },
    [isReportView, onSelectGlobal],
  );

  const onDeletePage = useRecoilCallback(
    ({ set, snapshot }) =>
      async (pageIndex: number) => {
        const pages = await snapshot.getPromise(viewPages);

        const newPages = [...pages.slice(0, pageIndex), ...pages.slice(pageIndex + 1)];
        const idsToDelete = pages[pageIndex]!.layout.map((l: Layout) => l.i);

        set(viewPages, newPages);
        set(allBlockIdsState, (allBlockIds) => allBlockIds.filter((id) => !idsToDelete.includes(id)));
      },
    [],
  );

  const onDuplicatePage = useRecoilCallback(
    ({ set, snapshot }) =>
      async (pageIndex: number) => {
        const pages = await snapshot.getPromise(viewPages);
        const newPage = cloneDeep(pages[pageIndex]!);
        const newBlockIds: BlockId[] = [];

        await Promise.all(
          pages[pageIndex]!.layout.map(async (item: Layout, index: number) => {
            const newRefId = getRandomId();
            newPage.layout[index] = {
              ...item,
              i: newRefId,
            };
            await cloneBlockState(item.i, newRefId);
            newBlockIds.push(newRefId);
          }),
        );

        set(allBlockIdsState, (current) => current.concat(newBlockIds));
        const newPages = [...pages.slice(0, pageIndex + 1), newPage, ...pages.slice(pageIndex + 1)];
        set(viewPages, newPages);
        onSelectPage(pageIndex + 1);
      },
    [cloneBlockState, onSelectPage],
  );

  const onAddNewPage = useRecoilCallback(
    ({ set, snapshot }) =>
      async (page: Page) => {
        const pages = await snapshot.getPromise(viewPages);
        const newPages = [...pages, page];
        set(viewPages, newPages);
        onSelectPage(pages.length);
      },
    [onSelectPage],
  );

  const blockOptions = useMemo(
    () =>
      sortBy(
        Object.values(blockSettingMapper).map((item) => ({
          label: item.title,
          value: item,
        })),
        'label',
      ),
    [blockSettingMapper],
  );

  const saveAllocatedPortfolio = useRecoilCallback(
    ({ snapshot, refresh, set }) =>
      async () => {
        const notificationId = Notifications.notify('Saving portfolio...', NotificationType.LOADING);
        const openSubject = await snapshot.getPromise(openAllocatorSubject);
        const subject = await snapshot.getPromise(allocatorAnalysisSubject(openSubject));
        if (!subject?.portfolio) {
          return;
        }
        try {
          const updatedPortfolio = await updatePortfolioV3(subject.portfolio.id, subject.portfolio);
          refresh(originalAnalysisSubjectQuery(openSubject));
          set(
            allocatorAnalysisSubject(openSubject),
            new AnalysisSubject({ ...updatedPortfolio.content }, 'portfolio', {
              ...subject.getOptionsCopy(),
              strategyId: subject.strategyId,
            }),
          );
          Notifications.notifyUpdate(notificationId, 'Successfully updated portfolio', NotificationType.SUCCESS);
        } catch (e) {
          logExceptionIntoSentry(e);
          Notifications.notifyUpdate(
            notificationId,
            'An error occurred updating the portfolio',
            NotificationType.ERROR,
          );
        }
      },
    [],
  );

  const savePrivateAllocatorPortfolio = useRecoilCallback(
    ({ snapshot, refresh, set }) =>
      async () => {
        const savingPortfolioNotification = Notifications.notify('Saving portfolio...', NotificationType.LOADING);
        try {
          const privateAllocatorConfig = await snapshot.getPromise(openPrivateAllocatorConfig);
          const subject = await snapshot.getPromise(allocatorAnalysisSubject(privateAllocatorConfig));

          const response = await savePrivatePortfolio(subject?.privatePortfolio);
          if (isRequestSuccessful(response)) {
            const savedPortfolio = response.content;
            const newStudioSubject = { privatePortfolioId: savedPortfolio.id };
            refresh(originalAnalysisSubjectQuery(newStudioSubject));

            set(openPrivateAllocatorPortfolio, savedPortfolio);
            set(allocatorAnalysisSubject(newStudioSubject), new AnalysisSubject(savedPortfolio, 'private-portfolio'));

            Notifications.notifyUpdate(
              savingPortfolioNotification,
              'Portfolio saved successfully.',
              NotificationType.SUCCESS,
            );
          } else {
            Notifications.notifyUpdate(
              savingPortfolioNotification,
              'An error occurred updating the portfolio.',
              NotificationType.ERROR,
            );
          }
        } catch (error) {
          logExceptionIntoSentry(error);
          Notifications.notifyUpdate(
            savingPortfolioNotification,
            'An error occurred updating the portfolio.',
            NotificationType.ERROR,
          );
        }
      },
    [],
  );

  const handleAfterUnsavedChangesAction = useRecoilCallback(
    ({ snapshot }) =>
      async (event: 'cancel' | 'proceed' | 'saveAndProceed' | 'saveAndProceedAllocatorOnly') => {
        setAfterUnsavedChangesAction(undefined);

        if (event === 'cancel') {
          afterUnsavedChangesAction?.cancelCallback?.();
          return;
        }

        // TODO is this meant to be the original name?
        const savedName = await snapshot.getPromise(analysisViewNameState);
        const savedId = await snapshot.getPromise(analysisViewIdState);
        const originalView = {
          savedName,
          savedId,
        };

        if (event === 'saveAndProceed') {
          const [saveResult] = await Promise.all([
            hasUnsavedChanges ? onSave() : undefined,
            hasUnsavedReturnsAllocatorChanges ? saveAllocatedPortfolio() : undefined,
            hasUnsavedPrivatesAllocatorChanges ? savePrivateAllocatorPortfolio() : undefined,
          ]);

          const finalView = saveResult ?? originalView;
          afterUnsavedChangesAction?.proceedCallback(finalView.savedName, finalView.savedId);
          return;
        }

        if (event === 'saveAndProceedAllocatorOnly') {
          await Promise.all([
            hasUnsavedReturnsAllocatorChanges ? saveAllocatedPortfolio() : undefined,
            hasUnsavedPrivatesAllocatorChanges ? savePrivateAllocatorPortfolio() : undefined,
          ]);

          afterUnsavedChangesAction?.proceedCallback(originalView.savedName, originalView.savedId);
          return;
        }

        if (event === 'proceed') {
          afterUnsavedChangesAction?.proceedCallback(originalView.savedName, originalView.savedId);
          return;
        }

        throw assertExhaustive(event);
      },
    [
      setAfterUnsavedChangesAction,
      afterUnsavedChangesAction,
      hasUnsavedChanges,
      onSave,
      hasUnsavedReturnsAllocatorChanges,
      saveAllocatedPortfolio,
      hasUnsavedPrivatesAllocatorChanges,
      savePrivateAllocatorPortfolio,
    ],
  );

  const onPdfExport = useRecoilCallback(
    ({ snapshot }) =>
      async (isInternal: boolean) => {
        if (hasUnsavedChanges || hasUnsavedReturnsAllocatorChanges || hasUnsavedPrivatesAllocatorChanges) {
          const id = await snapshot.getPromise(analysisViewIdState);
          await new Promise<void>((resolve) => {
            setAfterUnsavedChangesAction({
              proceedCallback: async (analysisViewName, analysisViewId) => {
                await onExport(isInternal, analysisViewName, analysisViewId);
                resolve();
              },
              cancelCallback: () => resolve(),
              hideDiscardBtn: !id,
            });
          });
        } else {
          await onExport(isInternal);
        }
      },
    [
      hasUnsavedReturnsAllocatorChanges,
      hasUnsavedPrivatesAllocatorChanges,
      hasUnsavedChanges,
      onExport,
      setAfterUnsavedChangesAction,
    ],
  );

  const checkDuplicateReportName = useRecoilCallback(
    ({ set, snapshot }) =>
      async (updatedReportName: string) => {
        const currentName = await snapshot.getPromise(analysisViewNameState);
        if (updatedReportName === currentName) {
          setIsDuplicateReportName(false);
          setIsCheckingDuplicateReportName(false);
          return;
        }

        checkViewName(updatedReportName)
          .then((isDuplicateName) => {
            if (isDuplicateName.content) {
              Notifications.notify(
                `Report name '${updatedReportName}' already exists.  Please choose a different name.`,
                NotificationType.INFO,
              );
            }
            setIsDuplicateReportName(isDuplicateName.content);
          })
          .finally(() => {
            set(analysisViewNameState, updatedReportName);
            setIsCheckingDuplicateReportName(false);
          });
      },
    [setIsCheckingDuplicateReportName, setIsDuplicateReportName],
  );

  const [reportName, setReportName] = useDebounceToGlobal(analysisViewName ?? '', checkDuplicateReportName);
  const [reportNameValue, setReportNameValue] = useDebounceToGlobal(reportName, setReportName);

  const onChangeReportName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
      setIsCheckingDuplicateReportName(true);
      const updatedReportName = typeof e === 'string' ? e : e.target.value;
      setIsDuplicateReportName(false);
      setReportNameValue(updatedReportName);
    },
    [setIsCheckingDuplicateReportName, setIsDuplicateReportName, setReportNameValue],
  );

  useEffect(() => {
    if (firstOpeningOfTheView) {
      setFirstOpeningOfTheView(false);
      onSelectGlobal(true);
      setLeftPanelOpen(true);
      setReportNameValue(analysisViewName ?? '');
      setReportName(analysisViewName ?? '');
    }
  }, [
    firstOpeningOfTheView,
    onSelectGlobal,
    openReportConfigModal,
    setFirstOpeningOfTheView,
    setReportName,
    setReportNameValue,
    setLeftPanelOpen,
    analysisViewName,
  ]);

  // Update url if analysis view id changes
  useEffect(() => {
    setReportNameValue(analysisViewName ?? '');
    setReportName(analysisViewName ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysisViewId]);

  // Memo the returned object, otherwise consumers were recomputing and rerendering unnecessarily.
  return useMemo(
    () => ({
      isSaving,
      noAccessModifiedView: !!viewOwner && viewOwner.id !== profileSettings?.user.id,
      blockOptions,
      // block toolbar actions
      onBlockReorderUp,
      onBlockReorderDown,
      onDeleteBlock,
      onInsertBlock,
      onDuplicateBlock,
      // Page actions
      onDuplicatePage,
      // studio toolbar actions
      onSave,
      onSaveAs,
      handleAfterUnsavedChangesAction,
      onDeletePage,
      onAddNewPage,
      onPdfExport,
      reportName,
      reportNameValue,
      setReportName,
      setReportNameValue,
      onChangeReportName,
      isOpenReportConfigModal,
      openReportConfigModal,
      closeReportConfigModal,
    }),
    [
      viewOwner,
      blockOptions,
      closeReportConfigModal,
      handleAfterUnsavedChangesAction,
      isOpenReportConfigModal,
      isSaving,
      onAddNewPage,
      onBlockReorderDown,
      onBlockReorderUp,
      onChangeReportName,
      onDeleteBlock,
      onDeletePage,
      onDuplicateBlock,
      onDuplicatePage,
      onInsertBlock,
      onPdfExport,
      onSave,
      onSaveAs,
      openReportConfigModal,
      profileSettings?.user.id,
      reportName,
      reportNameValue,
      setReportName,
      setReportNameValue,
    ],
  );
};

export default useStudioToolbar;
